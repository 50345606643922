<template>
  <table align="center" width="900" border="0" cellspacing="0" cellpadding="0" bgcolor="ffffff">
    <tr>
      <td>
        <img src="http://hummingdi.cafe24.com/2024SHE/SHE2024_eDM_900x_eDM_0830.jpg" usemap="#SHE20240830">
				<map name="SHE20240830" id="SHE20240830">
					
					<area  shape="rect" coords="522,2660,860,2726" href="https://www.instagram.com/skinhealthexpo?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank">			

				</map>
      </td>
    </tr>

  </table>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
body {margin:0; padding:0; line-height:0;border:0; font-size:0;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
table{width: 100%;}
img{width: 100%;}
</style>
